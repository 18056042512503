/* TODO: see if there's a better way of importing fonts into material-ui */

@font-face {
  src: url(fonts/raleway-v18-latin/raleway-v18-latin-regular.ttf);
  font-family: raleway;
}
@font-face {
  src: url(fonts/Helvetica-Round/Helvetica-Rounded-LT-Black.ttf);
  font-family: Helvetica-Round;
  font-style: regular;
}

@font-face {
  src: url(fonts/Helvetica-Round/Helvetica-Rounded-LT-Bold.ttf);
  font-family: Helvetica-Round-Bold;
  font-style: regular;
}

* {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

label.Mui-focused {
  color: black !important;
}
